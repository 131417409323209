var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"follow-info"},[_c('van-form',{attrs:{"label-width":"90px","show-error":false},on:{"submit":_vm.onSubmitAppointGarden}},[_c('van-field',{attrs:{"readonly":"","clickable":"","name":"calendar","value":_vm.appointGardenForm.appointDate,"label":"预约时间","placeholder":"请选择预约时间","required":"","rules":[{ required: true, message: '请选择预约时间' }]},on:{"click":function($event){_vm.showAppointDatePicker = true}}}),_c('van-field',{attrs:{"readonly":"","clickable":"","name":"picker","value":_vm.appointGardenForm.age,"label":"长者年龄","placeholder":"请选择长者年龄","required":"","rules":[{ required: true, message: '请选择长者年龄' }],"clearable":""},on:{"click":function($event){_vm.showAppointElderAge = true}}}),_c('van-field',{attrs:{"readonly":"","clickable":"","name":"picker","value":_vm.appointGardenForm.health,"label":"健康状况","placeholder":"请选择健康状况","required":"","rules":[{ required: true, message: '请选择健康状况' }],"clearable":""},on:{"click":function($event){_vm.showAppointHealth = true}}}),_c('van-field',{attrs:{"readonly":"","clickable":"","name":"picker","value":_vm.appointGardenForm.appointNumber,"label":"预约人数","placeholder":"请选择预约人数","required":"","rules":[{ required: true, message: '请选择预约人数' }],"clearable":""},on:{"click":function($event){_vm.showAppointNumber = true}}}),_vm._l((_vm.isOneselfList),function(item,index){return _c('div',{key:index},[_c('van-button',{attrs:{"block":"","type":"default"},on:{"click":function($event){$event.preventDefault();return _vm.getIsOneself(index)}}},[_c('span',{class:_vm.isOneselfIndex === index
              ? 'oneself-class-active'
              : 'oneself-class'},[_vm._v(_vm._s(item))]),(_vm.isOneselfIndex === index)?_c('img',{staticStyle:{"width":"14px","height":"14px","margin-right":"5px"},attrs:{"src":require("../../../assets/gou.png"),"alt":""}}):_vm._e()])],1)}),(_vm.isOneselfIndex === 1)?_c('div',[_c('van-field',{attrs:{"label":"姓名","placeholder":"请输入姓名","required":"","rules":[{ required: true, message: '请输入客户姓名' }],"maxlength":"20","clearable":""},model:{value:(_vm.appointGardenForm.name),callback:function ($$v) {_vm.$set(_vm.appointGardenForm, "name", $$v)},expression:"appointGardenForm.name"}}),_c('van-field',{attrs:{"label":"联系电话","type":"digit","placeholder":"请输入电话号码","required":"","maxlength":"11","clearable":"","rules":[
          { required: true, message: '请输入电话' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' } ]},model:{value:(_vm.appointGardenForm.phone),callback:function ($$v) {_vm.$set(_vm.appointGardenForm, "phone", $$v)},expression:"appointGardenForm.phone"}}),_c('van-field',{attrs:{"label":"与长者关系","placeholder":"请输入与长者关系","required":"","rules":[{ required: true, message: '请输入与长者关系' }],"maxlength":"20","clearable":""},model:{value:(_vm.appointGardenForm.relation),callback:function ($$v) {_vm.$set(_vm.appointGardenForm, "relation", $$v)},expression:"appointGardenForm.relation"}}),_c('van-field',{attrs:{"label":"身份证信息","placeholder":"请输入身份证信息","required":"","rules":[
          { required: true, message: '请输入身份证号' },
          {
            pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
            message: '身份证号码格式错误！',
          } ],"maxlength":"18","clearable":""},model:{value:(_vm.appointGardenForm.idCard),callback:function ($$v) {_vm.$set(_vm.appointGardenForm, "idCard", $$v)},expression:"appointGardenForm.idCard"}})],1):_vm._e(),_c('div',{staticStyle:{"margin":"16px","margin-top":"40px"}},[_c('van-button',{attrs:{"block":"","type":"info","native-type":"submit","loading":_vm.btn_loading,"loading-text":"确认"}},[_vm._v("确认")])],1)],2),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showAppointDatePicker),callback:function ($$v) {_vm.showAppointDatePicker=$$v},expression:"showAppointDatePicker"}},[_c('van-datetime-picker',{attrs:{"type":"datetime","title":"选择年月日时分","min-date":_vm.minDate},on:{"confirm":_vm.onConfirmAppointDatePicker,"cancel":function($event){_vm.showAppointDatePicker = false}},model:{value:(_vm.currentDate),callback:function ($$v) {_vm.currentDate=$$v},expression:"currentDate"}})],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showAppointElderAge),callback:function ($$v) {_vm.showAppointElderAge=$$v},expression:"showAppointElderAge"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.appointElderAgeList},on:{"confirm":_vm.onConfirmElderAge,"cancel":function($event){_vm.showAppointElderAge = false}}})],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showAppointHealth),callback:function ($$v) {_vm.showAppointHealth=$$v},expression:"showAppointHealth"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.appointHealthList},on:{"confirm":_vm.onConfirmHealth,"cancel":function($event){_vm.showAppointHealth = false}}})],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showAppointNumber),callback:function ($$v) {_vm.showAppointNumber=$$v},expression:"showAppointNumber"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.reservationNumberList},on:{"confirm":_vm.onConfirmNumber,"cancel":function($event){_vm.showAppointNumber = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }