<template>
  <div class="follow-info">
    <van-form
      @submit="onSubmitAppointGarden"
      label-width="90px"
      :show-error="false"
    >
      <van-field
        readonly
        clickable
        name="calendar"
        :value="appointGardenForm.appointDate"
        label="预约时间"
        placeholder="请选择预约时间"
        required
        :rules="[{ required: true, message: '请选择预约时间' }]"
        @click="showAppointDatePicker = true"
      />
      <van-field
        readonly
        clickable
        name="picker"
        :value="appointGardenForm.age"
        label="长者年龄"
        placeholder="请选择长者年龄"
        @click="showAppointElderAge = true"
        required
        :rules="[{ required: true, message: '请选择长者年龄' }]"
        clearable
      />
      <van-field
        readonly
        clickable
        name="picker"
        :value="appointGardenForm.health"
        label="健康状况"
        placeholder="请选择健康状况"
        @click="showAppointHealth = true"
        required
        :rules="[{ required: true, message: '请选择健康状况' }]"
        clearable
      />
      <van-field
        readonly
        clickable
        name="picker"
        :value="appointGardenForm.appointNumber"
        label="预约人数"
        placeholder="请选择预约人数"
        @click="showAppointNumber = true"
        required
        :rules="[{ required: true, message: '请选择预约人数' }]"
        clearable
      />
      <div v-for="(item, index) in isOneselfList" :key="index">
        <van-button block type="default" @click.prevent="getIsOneself(index)">
          <span
            :class="
              isOneselfIndex === index
                ? 'oneself-class-active'
                : 'oneself-class'
            "
            >{{ item }}</span
          >
          <img
            src="../../../assets/gou.png"
            alt
            style="width: 14px; height: 14px; margin-right: 5px"
            v-if="isOneselfIndex === index"
          />
        </van-button>
      </div>

      <div v-if="isOneselfIndex === 1">
        <van-field
          label="姓名"
          placeholder="请输入姓名"
          required
          v-model="appointGardenForm.name"
          :rules="[{ required: true, message: '请输入客户姓名' }]"
          maxlength="20"
          clearable
        />
        <van-field
          v-model="appointGardenForm.phone"
          label="联系电话"
          type="digit"
          placeholder="请输入电话号码"
          required
          maxlength="11"
          clearable
          :rules="[
            { required: true, message: '请输入电话' },
            { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
          ]"
        />
        <van-field
          label="与长者关系"
          placeholder="请输入与长者关系"
          required
          v-model="appointGardenForm.relation"
          :rules="[{ required: true, message: '请输入与长者关系' }]"
          maxlength="20"
          clearable
        />
        <van-field
          label="身份证信息"
          placeholder="请输入身份证信息"
          required
          v-model="appointGardenForm.idCard"
          :rules="[
            { required: true, message: '请输入身份证号' },
            {
              pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
              message: '身份证号码格式错误！',
            },
          ]"
          maxlength="18"
          clearable
        />
      </div>
      <div style="margin: 16px; margin-top: 40px">
        <van-button
          block
          type="info"
          native-type="submit"
          :loading="btn_loading"
          loading-text="确认"
          >确认</van-button
        >
      </div>
    </van-form>
    <!-- 预约时间 -->
    <van-popup v-model="showAppointDatePicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择年月日时分"
        :min-date="minDate"
        @confirm="onConfirmAppointDatePicker"
        @cancel="showAppointDatePicker = false"
      />
    </van-popup>
    <!-- 长者年龄 -->
    <van-popup v-model="showAppointElderAge" position="bottom">
      <van-picker
        show-toolbar
        :columns="appointElderAgeList"
        @confirm="onConfirmElderAge"
        @cancel="showAppointElderAge = false"
      />
    </van-popup>
    <!-- 健康状况 -->
    <van-popup v-model="showAppointHealth" position="bottom">
      <van-picker
        show-toolbar
        :columns="appointHealthList"
        @confirm="onConfirmHealth"
        @cancel="showAppointHealth = false"
      />
    </van-popup>
    <!-- 预约人数 -->
    <van-popup v-model="showAppointNumber" position="bottom">
      <van-picker
        show-toolbar
        :columns="reservationNumberList"
        @confirm="onConfirmNumber"
        @cancel="showAppointNumber = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { getSelectOptionsList, orderconfirms } from "@/api/api";
import moment from "moment/moment";
export default {
  data() {
    return {
      // 按钮加载
      btn_loading: false,
      customerId: "",
      // 时间列表显示与隐藏
      showPicker: false,
      minDate: new Date(),
      min_date: new Date(2020, 8, 9),
      currentDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      // 预约入园表单数据
      appointGardenForm: {
        appointDate: "",
        age: "",
        // 年龄索引
        ageIndex: 0,
        health: "",
        // 健康状况索引
        healthIndex: 0,
        appointNumber: "",
        // 人数索引
        appointNumberIndex: 0,
        // 本人信息
        name: "",
        phone: "",
        relation: "",
        idCard: "",
      },
      // 预约时间控件
      showAppointDatePicker: false,
      // 长者年龄
      appointElderAgeList: [],
      // 长者年龄列表控件的显示与隐藏
      showAppointElderAge: false,
      // 健康状况
      appointHealthList: [],
      // 健康状况列表控件的显示与隐藏
      showAppointHealth: false,
      // 预约人数列表数据
      reservationNumberList: [],
      // 预约人数列表控件的显示与隐藏
      showAppointNumber: false,
      // 本人非本人
      isOneselfList: ["本人", "非本人"],
      // 是否本人判断
      isOneselfIndex: 0,
    };
  },
  created() {
    // 下拉列表选择
    this.getSelectOptionsList();
  },
  methods: {
    // 下拉列表选择
    getSelectOptionsList() {
      getSelectOptionsList().then((res) => {
        if (res.code === 200) {
          this.appointElderAgeList = res.data.ages;
          this.appointHealthList = res.data.healthconditions;
          this.reservationNumberList = res.data.numbers;
        }
      });
    },
    // 预约入园信息预约时间
    onConfirmAppointDatePicker(time) {
      this.appointGardenForm.appointDate = moment(time).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.showAppointDatePicker = false;
    },
    // 长者年龄选择
    onConfirmElderAge(value, index) {
      this.appointGardenForm.ageIndex = index;
      if (this.appointGardenForm.ageIndex === 0) {
        this.$toast("请选择长者年龄");
      } else {
        this.appointGardenForm.age = value;
        this.showAppointElderAge = false;
      }
    },
    // 健康状况选择
    onConfirmHealth(value, index) {
      this.appointGardenForm.healthIndex = index;
      if (this.appointGardenForm.healthIndex === 0) {
        this.$toast("请选择健康状况");
      } else {
        this.appointGardenForm.health = value;
        this.showAppointHealth = false;
      }
    },
    // 预约人数选择
    onConfirmNumber(value, index) {
      this.appointGardenForm.appointNumberIndex = index;
      if (this.appointGardenForm.appointNumberIndex === 0) {
        this.$toast("请选择预约人数");
      } else {
        this.appointGardenForm.appointNumber = value;
        this.showAppointNumber = false;
      }
    },
    // 判断选择本人非本人
    getIsOneself(index) {
      this.isOneselfList.map((item, key) => {
        if (index === key) {
          this.isOneselfIndex = key;
        }
      });
    },
    // 预约入园信息
    onSubmitAppointGarden() {
      let follow = this.$route.query.follow;
      this.customerId = this.$route.query.id;
      const oneself =
        this.isOneselfIndex === 0 ? 1 : this.isOneselfIndex === 1 ? 2 : "";
      if (this.isOneselfIndex === "") {
        this.$toast("请选择是否本人");
      } else if (this.appointGardenForm.ageIndex === 0) {
        this.$toast("请选择长者年龄");
      } else if (this.appointGardenForm.healthIndex === 0) {
        this.$toast("请选择健康状况");
      } else if (this.appointGardenForm.appointNumberIndex === 0) {
        this.$toast("请选择预约人数");
      } else {
        this.btn_loading = true;
        orderconfirms({
          id: this.customerId,
          nl: this.appointGardenForm.ageIndex,
          jk: this.appointGardenForm.healthIndex,
          rs: this.appointGardenForm.appointNumberIndex,
          time: this.appointGardenForm.appointDate,
          oneself: oneself,
          name: this.appointGardenForm.name,
          mobile: this.appointGardenForm.phone,
          relation: this.appointGardenForm.relation,
          idcard: this.appointGardenForm.idCard,
        })
          .then((res) => {
            if (res.code === 200) {
              this.$toast.success(res.data);
              this.btn_loading = false;
              if (follow) {
                this.$router.push({ path: "/myFollow" });
              } else {
                this.$router.push({ path: "/myClient" });
              }
            }
          })
          .catch(() => {
            this.btn_loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.follow-info {
  margin-top: 22px;
  background-color: #fff;
}
/deep/.van-button--info,
/deep/.van-button--primary {
  background-color: #f3973b;
  border: #f3973b;
}
.oneself-class-active {
  color: #f3973b;
}
</style>